@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-pdfviewer/styles/material.css';

body {
  margin: 0;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.google-map {
  width: 100%;
  height: 100%;
}

.ant-picker-cell-selected {
  &::before {
    color: white !important;
  }
  .ant-picker-cell-inner {
    background: #002885 !important;
    border-radius: 50% !important;
  }
}

.ant-picker-cell-in-view .ant-picker-cell-inner:before {
  border-color: #002885 !important;
}

.ant-modal-root .ant-modal-wrap {
  z-index: 1005;

  .ant-modal {
    width: 480px !important;

    @media screen and (max-width: 480px) {
      width: calc(100vw - 15px) !important;
    }
  }

  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-confirm-content {
        margin-top: 15px;
      }

      .ant-modal-confirm-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 30px;

        .ant-btn {
          height: 40px;
          width: 30%;

          &.ant-btn-primary {
            margin-left: 15px;
            background-color: #002885;
            border: 1px solid #002885;
            color: #fff;

            &:hover {
              opacity: 0.9;
            }
          }
          &.ant-btn-default {
            &:hover,
            &:focus {
              border: 1px solid #002885;
              color: #002885;
            }
          }
        }
      }
    }
  }
}

.ant-modal-root .ant-modal-wrap .ant-modal:has(.ant-modal-confirm-body-wrapper) {
  width: 350px !important;

  .ant-modal-content {
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: #fff !important;
  }
}

.ant-modal-confirm-body-wrapper {
  .ant-modal-confirm-body-has-title {
    width: 100%;
    & > .anticon-exclamation-circle {
      display: none;
    }
  }
  .ant-modal-confirm-paragraph {
    width: 100%;
    max-width: 100% !important;
    display: block !important;

    .ant-modal-confirm-title {
      justify-content: flex-end;
      align-items: center;
      & svg {
        font-size: 18px;
      }
      & path {
        fill: white;
      }
    }
  }
  & .ant-modal-confirm-content {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    margin-top: 0 !important;
  }
  & .ant-modal-confirm-btns {
    margin: 10px 20px !important;
    & button {
      height: 30px !important;
      padding: 0 !important;
      width: 90px !important;
      border-radius: 2px !important;
      border: 1px solid #002885;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.25);
      color: #002885;
    }
    & .ant-btn-primary {
      outline: none;
      border: 1px solid #002885;
      background: #002885;
      color: #fff;
      margin-left: 16px;
    }
  }
}

.drag-project-item {
  z-index: 100;
}

.row-dragging .ant-table-cell,
.mobile-row-dragging .ant-table-cell {
  padding: 16px 0 0 16px;
  visibility: hidden;
  overflow: hidden;
}

.row-dragging .drag-visible,
.mobile-row-dragging .drag-visible {
  visibility: visible;
  background-color: #fff;
}

.row-dragging .ant-table-cell:nth-child(2),
.mobile-row-dragging .ant-table-cell:nth-child(3) {
  width: 100%;
  & .ant-typography {
    width: auto;
  }
}

.ant-select-dropdown {
  .ant-select-item {
    min-height: 15px;
    padding: 0 8px;
    color: #494949;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;

    &.ant-select-item-option-active,
    &.ant-select-item-option-selected {
      color: #002885;
      font-weight: 400;
      background-color: #39589f2b;
    }
  }
}

.smaller_dropdown {
  .ant-select-item-option {
    min-height: 15px;
    padding: 2px 5px;
  }
}

#stamp_elem {
  width: 100px;
  height: 35px;
  background-color: #e6eddf;
  border: 3px solid #647d48;
  color: #647d48;

  p {
    font-family: 'Roboto', sans-serif;
    text-align: center;

    &:first-child {
      margin-bottom: 2px;
      margin-top: 3px;
      font-weight: 700;
      font-size: 13px;
    }
    &:last-child {
      margin-bottom: 0;
      margin-top: 0;
      font-size: 9px;
      font-style: italic;
      font-weight: 700;
    }
  }
}

.ant-select-dropdown {
  border: 1px solid #002885;
}

#container_context_menu {
  transform: scale(0);
}

.ant-table {
  margin-block: 0 !important;
  margin-inline: 0 !important;
}

.custom-popup-dark {
  .ant-picker-panel-layout {
    background-color: #002885;
    .ant-picker-now-btn {
      color: white;
    }
  }
  .ant-picker-content thead th,
  .ant-picker-header,
  .ant-picker-header button,
  .ant-picker-cell-inner {
    color: white;
  }
  .ant-picker-cell-disabled::before,
  .ant-picker-cell-disabled .ant-picker-cell-inner {
    background-color: #476bc1;
  }
}

.custom-popup-light {
  .ant-picker-panel-layout {
    background-color: white;
  }

  .ant-picker-content thead th,
  .ant-picker-header,
  .ant-picker-header button,
  .ant-picker-cell-inner {
    color: black;
  }
}
